<template>
  <v-sheet class="customer" id="customer">
    <v-row>
      <!-- {{ this.$route.params.id }} -->
      <!-- <pre>{{product_supplierss}}</pre> -->
      <v-col md="12" class="">
        <v-row>
          <v-col md="6" class="my-auto py-0">
            <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
          </v-col>
          <v-col md="6" class="text-right py-0">
            <v-btn
              class="mr-2"
              v-if="stepper.value > 1"
              :disabled="pageLoading"
              depressed
              tile
              v-on:click="stepper.value--"
            >
              Previous
            </v-btn>
            <v-btn
              :disabled="pageLoading || !firstStepValid"
              :loading="pageLoading"
              class="white--text mx-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateOrCreate"
            >
              <!-- () => {
                  if (stepper.value === 1) {
                    if (firstStepValid) {
                      stepper.value++;
                    }
                    // updateOrCreate();
                  } else if(stepper.value ===2) {
                    if (secondStepValid) {
                      stepper.value++;
                    }
                  }
                  else if(stepper.value ===3) {
                    updateOrCreate();
                  }
                } -->
              {{ stepper.value === stepper.header?.length ? "Save" : "Next" }}
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" class="mt-0 py-0">
        <!-- this.$ref["productAddForm"].validate() -->
        <!-- {{ firstStepValid }} - {{ stepper.value }} -->
        <v-form
          ref="productAddForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <!-- <v-container fluid class="py-0">
            <v-row> -->
          <v-stepper v-model="stepper.value">
            <v-stepper-header class="border-light-grey" style="height: auto; border-bottom: none">
              <v-stepper-step
                v-for="(step, idex) in stepper.header"
                :key="`stepper_product_add_${idex + 1}_${step.title}`"
                :complete="stepper.value > idex + 1"
                :step="idex + 1"
                class="py-2"
                :rules="[() => true]"
                color="#0d47a1"
              >
                <span class="h6">{{ step.title }}</span>
                <!-- <small style="color: var(--red); font-weight: 600; font-size: 16px">
                  Alert message
                </small> -->
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                step="1"
                class="py-2 px-4"
                style="height: calc(100vh - 188px); overflow-y: auto"
              >
                <v-form
                  ref="firstStep"
                  v-model="firstStepValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateOrCreate"
                >
                  <v-row>
                    <v-col md="6">
                      <v-row style="margin: 0% !important">
                        <v-col md="12" class="mb-2 border-light-grey">
                          <v-row style="margin: 0% !important">
                            <v-col md="12" class="my-0 py-0">
                              <label
                                for="product-name-en"
                                class="field-label h4 mt-0 mb-1 font-weight-bold"
                              >
                                Product Name
                              </label>
                            </v-col>

                            <v-col md="12">
                              <v-row style="margin: 0% !important">
                                <v-col md="12" class="my-0 pa-0">
                                  <label
                                    for="product-name-en"
                                    class="field-label mt-0 mb-1 required"
                                  >
                                    English
                                  </label>
                                </v-col>

                                <v-col md="12" class="my-0 pa-0">
                                  <TextInput
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    id="product-name-en"
                                    placeholder="Product Name (English)"
                                    v-model="product.name_en"
                                    :rules="[vrules.required(product.name_en, 'Product Name')]"
                                    :class="{ required: !product.name_en }"
                                  >
                                  </TextInput>
                                </v-col>

                                <v-col md="12" class="pb-0 px-0">
                                  <label for="product-name-cn" class="field-label mt-0 mb-1">
                                    Chinese
                                  </label>
                                </v-col>
                                <v-col md="12" class="my-0 pa-0">
                                  <TextInput
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    id="product-name-cn"
                                    placeholder="Product Name (Chinese)"
                                    v-model="product.name_cn"
                                  ></TextInput>
                                </v-col>

                                <v-col md="12" class="pb-0 px-0">
                                  <label for="product-name-my" class="field-label mt-0 mb-1"
                                    >Myanmar</label
                                  >
                                </v-col>
                                <v-col md="12" class="my-0 pa-0">
                                  <TextInput
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    id="product-name-my"
                                    v-model="product.name_my"
                                    placeholder="Product Name (Myanmar)"
                                  ></TextInput>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col md="12" class="mt-2 border-light-grey">
                          <v-row style="margin: 0% !important">
                            <v-col md="12" class="py-0">
                              <label
                                for="product-name-en"
                                class="field-label h4 mt-0 mb-1 font-weight-bold"
                              >
                                Brand & Category
                              </label>
                            </v-col>
                            <v-col md="12">
                              <v-row style="margin: 0% !important">
                                <v-col md="12" class="pa-0">
                                  <label for="brand" class="field-label mt-0 mb-1">Brand</label>
                                </v-col>

                                <v-col md="11" class="my-0 pa-0">
                                  <SelectInput
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    :items.sync="brands"
                                    :item-value="name"
                                    id="brand"
                                    v-model="product.brand"
                                    placeholder="Brand"
                                  ></SelectInput>
                                </v-col>

                                <!-- <v-col md="1" class="my-0 pa-0 d-flex align-center justify-center">
                                  <v-tooltip right>
                                    <template v-slot:activator="{ on, attr }">
                                      <div
                                        v-bind="attr"
                                        v-on="on"
                                        class="ml-3"
                                        v-on:click="openDialog('Brand', 800, true)"
                                      >
                                        <v-icon color="#0d47a1">settings</v-icon>
                                      </div>
                                    </template>
                                    <span>Add more brands</span>
                                  </v-tooltip>
                                </v-col> -->

                                <v-col md="12" class="pa-0">
                                  <v-row style="margin: 0% !important">
                                    <v-col md="12" class="pb-0 px-0">
                                      <label for="category" class="field-label mt-0 mb-1 required">
                                        Department Category
                                      </label>
                                    </v-col>

                                    <v-col md="11" class="my-0 pa-0">
                                      <SelectInput
                                        hide-details
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        :items.sync="categorys"
                                        @change="filter_category(product.deptCategory)"
                                        id="name"
                                        v-model="product.deptCategory"
                                        item-value="id"
                                        item-text="text"
                                        placeholder="Department Category"
                                        :rules="[vrules.required(product.deptCategory, 'Category')]"
                                        :class="{ required: !product.deptCategory }"
                                      >
                                      </SelectInput>
                                    </v-col>
                                    <!-- <v-col
                                      md="1"
                                      class="my-0 pa-0 d-flex align-center justify-center"
                                    >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attr }">
                                          <div
                                            v-bind="attr"
                                            v-on="on"
                                            class="ml-3"
                                            v-on:click="openDialog('Category', 800, true)"
                                          >
                                            <v-icon color="#0d47a1">settings</v-icon>
                                          </div>
                                        </template>
                                        <span>Add more categories</span>
                                      </v-tooltip>
                                    </v-col>
  -->
                                    <v-col md="12" class="pb-0 px-0">
                                      <label class="field-label mt-0 mb-1"> Category</label>
                                    </v-col>

                                    <v-col md="11" class="my-0 pa-0">
                                      <SelectInput
                                        hide-details
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        item-value="id"
                                        :items.sync="filterDepartments"
                                        id="dept-category"
                                        v-model="product.category"
                                        placeholder="Category"
                                      ></SelectInput>
                                    </v-col>
                                    <!-- :items.sync="filterDepartments && filterDepartments.length && filterDepartments.length > 0 ? filterDepartments :departments" -->

                                    <v-col md="12" class="pb-0 px-0">
                                      <label class="field-label mt-0 mb-1">Budget Category</label>
                                    </v-col>
                                    <v-col md="11" class="my-0 pa-0">
                                      <SelectInput
                                        hide-details
                                        :disabled="pageLoading"
                                        :loading="pageLoading"
                                        :items.sync="othercats"
                                        id="dept-category"
                                        v-model="product.otherCategory"
                                        :rules="[
                                          vrules.required(product.otherCategory, 'Category'),
                                        ]"
                                        :class="{ required: !product.otherCategory }"
                                        placeholder="Budget Category"
                                      ></SelectInput>
                                    </v-col>

                                    <!-- <v-col
                                      md="1"
                                      class="my-0 pa-0 d-flex align-center justify-center"
                                    >
                                      <v-tooltip right>
                                        <template v-slot:activator="{ on, attr }">
                                          <div
                                            v-bind="attr"
                                            v-on="on"
                                            class="ml-3"
                                            v-on:click="openDialog('Department category', 800, true)"
                                          >
                                            <v-icon color="#0d47a1">settings</v-icon>
                                          </div>
                                        </template>
                                        <span>Add more department categories</span>
                                      </v-tooltip>
                                    </v-col> -->
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- Product Image -->
                    <v-col md="6" class="">
                      <v-row
                        style="margin: 0% !important; height: 100% !important"
                        class="border-light-grey"
                      >
                        <!-- image -->
                        <v-col md="12" class="px-6 pb-0" style="height: 5vh">
                          <label
                            for="document-file-0"
                            class="field-label h3 font-weight-bold mt-0 mb-1 cursor-pointer"
                            >Product Image</label
                          >
                        </v-col>

                        <v-col
                          md="12"
                          align="center"
                          justify="center"
                          class="px-6 pb-6 d-flex align-center justify-center"
                        >
                          <!-- style="height: calc(66vh - 45px)" -->
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" style="height: 100%; width: 100%">
                                <ImageTemplate
                                  :src="getProfileImage"
                                  :maxHeight="250"
                                ></ImageTemplate>
                                <v-btn
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  class="white--text mx-2"
                                  depressed
                                  color="blue darken-4"
                                  tile
                                  style
                                  v-on:click="fileInput()"
                                >
                                  {{ product.image ? "Reset Image" : "Upload Image" }}
                                  <v-icon right style="font-size: 21px">
                                    {{
                                      `mdi-${product.image ? "refresh" : "cloud-upload-outline"}`
                                    }}
                                  </v-icon>
                                </v-btn>
                                <div class="mt-3">
                                  <label for="product-image" class="field-label mt-0 mb-1">
                                    Allowed JPG or PNG or JPEG.
                                  </label>
                                </div>
                              </div>
                            </template>
                            <span> Upload Images </span>
                          </v-tooltip>
                        </v-col>

                        <v-col md="12" class="pa-0" v-show="false">
                          <v-row class="pa-0" style="margin: 0% !important">
                            <v-col md="2" sm="3" align="center" justify="center" class="pa-0 mt-2">
                              <v-file-input
                                id="product-image"
                                accept="image/png, image/jpeg, image/bmp"
                                ref="fileInput"
                                class="d-none"
                                v-on:change="updateProfileImage"
                              >
                              </v-file-input>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content
                step="2"
                class="py-2 pb-5 px-1"
                style="height: calc(100vh - 188px); overflow-y: auto"
              >
                <v-form
                  ref="secondStep"
                  v-model="secondStepValid"
                  lazy-validation
                  v-on:submit.stop.prevent="updateOrCreate"
                >
                  <v-row style="margin: 0% !important" class="">
                    <v-col md="12">
                      <v-row style="margin: 0% !important" class="">
                        <v-col md="9" class="pl-0 pt-0">
                          <v-row style="margin: 0% !important">
                            <!-- UOM Details -->
                            <v-col md="12" class="pt-0 px-0 border-light-grey">
                              <v-row style="margin: 0% !important">
                                <v-col md="12" class="grey lighten-3 my-0">
                                  <label
                                    for="primary_sales_uom"
                                    class="h4 field-label mt-0 font-weight-bold"
                                  >
                                    UOM Details
                                  </label>
                                </v-col>

                                <v-col md="12" class="py-0">
                                  <v-row>
                                    <v-col md="12" class="pb-0">
                                      <v-row style="margin: 0% !important">
                                        <v-col
                                          md="2"
                                          style="max-width: max-content"
                                          class="pa-0 pt-5 pr-1 d-flex align-center"
                                        >
                                          <v-chip
                                            style="width: 100px"
                                            class="ma-2 text-white rounded-lg d-flex justify-center"
                                            color="blue"
                                          >
                                            Primary
                                          </v-chip>
                                        </v-col>

                                        <v-col md="4" class="pa-0 px-2">
                                          <v-row style="margin: 0% !important">
                                            <!-- Primary (sales) UOM -->
                                            <v-col md="12" class="pa-0">
                                              <label
                                                for="primary_sales_uom"
                                                class="field-label mt-0 mb-1 required"
                                              >
                                                Primary (sales) UOM
                                              </label>
                                            </v-col>
                                            <v-col md="12" class="pa-0">
                                              <SelectInput
                                                hide-details
                                                :readonly="isDisabled"
                                                :loading="pageLoading"
                                                :items.sync="primarySalesUOMList"
                                                @change="selectprimary(product.primary_sales_uom)"
                                                id="primary_sales_uom"
                                                v-model="product.primary_sales_uom"
                                                placeholder="Primary (sales) UOM"
                                                :rules="[
                                                  vrules.required(
                                                    product.primary_sales_uom,
                                                    'Primary (sales) UOM'
                                                  ),
                                                ]"
                                                :class="{ required: !product.primary_sales_uom }"
                                              ></SelectInput>
                                            </v-col>
                                          </v-row>
                                        </v-col>

                                        <!-- Secondary (purchase) UOM -->

                                        <v-col md="6" class="pa-0 pl-2">
                                          <v-row style="margin: 0% !important">
                                            <v-col md="12" class="pa-0">
                                              <label
                                                for="unit_description"
                                                class="field-label mt-0 mb-1 required"
                                              >
                                                Unit Description (Primary sales UOM)
                                              </label>
                                            </v-col>
                                            <v-col md="12" class="pa-0">
                                              <TextInput
                                                hide-details
                                                type="text"
                                                :readonly="isDisabled"
                                                :loading="pageLoading"
                                                id="unit_description"
                                                v-model="product.unit_description_psu"
                                                :rules="[
                                                  vrules.required(
                                                    product.unit_description_psu,
                                                    'Unit Description ( Primary sales UOM )'
                                                  ),
                                                ]"
                                                placeholder="Unit Description ( Primary sales UOM )"
                                                :class="{ required: !product.unit_description_psu }"
                                              ></TextInput>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>

                                    <v-col md="12" class="">
                                      <v-row style="margin: 0% !important">
                                        <v-col
                                          md="2"
                                          style="max-width: max-content"
                                          class="pa-0 pt-5 pr-1 d-flex align-center"
                                        >
                                          <v-chip
                                            style="width: 100px"
                                            class="ma-2 text-white rounded-lg d-flex justify-center"
                                            color="red"
                                          >
                                            Secondary
                                          </v-chip>
                                        </v-col>

                                        <v-col md="4" class="pa-0 px-5">
                                          <v-row>
                                            <v-col md="12" class="pa-0">
                                              <label
                                                for="secondary_purchase_uom"
                                                class="field-label mt-0 mb-1 required"
                                              >
                                                Secondary (purchase) UOM
                                              </label>
                                            </v-col>
                                            <v-col md="12" class="pa-0">
                                              <SelectInput
                                                hide-details
                                                :readonly="isDisabled"
                                                :loading="pageLoading"
                                                :items.sync="primarySalesUOMList"
                                                @change="
                                                  selectsecondary(product.secondary_purchase_uom)
                                                "
                                                id="secondary_purchase_uom"
                                                v-model="product.secondary_purchase_uom"
                                                placeholder="Secondary (purchase) UOM"
                                                :rules="[
                                                  vrules.required(
                                                    product.secondary_purchase_uom,
                                                    'Secondary (purchase) UOM'
                                                  ),
                                                ]"
                                                :class="{
                                                  required: !product.secondary_purchase_uom,
                                                }"
                                              ></SelectInput>
                                            </v-col>
                                          </v-row>
                                        </v-col>
                                      </v-row>
                                    </v-col>

                                    <!-- Reorder trigger -->
                                    <!-- <v-col md="9" class="pt-0 pr-2">
                                    <v-row style="margin: 0% !important">
                                      <v-col md="12" class="py-0 px-0">
                                        <label for="reorder-trigger" class="field-label mt-0 mb-1"
                                          >Reorder Trigger</label
                                        >
                                      </v-col>
    
                                      <v-col md="12" class="pa-0">
                                        <NumberInput
                                          hide-details
                                          type="number"
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          id="reorder-trigger"
                                          v-model="product.reorder_trigger"
                                          placeholder="Reorder Trigger"
                                        ></NumberInput>
                                      </v-col>
                                    </v-row>
                                  </v-col>
    
                                  <v-col md="3" class="pt-0 pl-2">
                                    <v-row style="margin: 0% !important">
                                      <v-col md="12" class="py-0 px-0">
                                        <label for="reorder_type" class="field-label mt-0 mb-1">
                                          Reorder Type
                                        </label>
                                      </v-col>
                                      <v-col md="12" class="pa-0">
                                        <SelectInput
                                          hide-details
                                          :disabled="pageLoading"
                                          :loading="pageLoading"
                                          :items="[
                                            { text: 'Primary', value: 'primary' },
                                            { text: 'Secondary', value: 'secondary' },
                                          ]"
                                          id="reorder_type"
                                          v-model="product.reorder_type"
                                          placeholder="Reorder type"
                                        ></SelectInput>
                                      </v-col>
                                    </v-row>
                                  </v-col> -->
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col md="9" class="pl-0">
                          <v-row style="background-color: #fff; margin: 0% !important">
                            <!-- border: 1px solid var(--v-secondary-darken1); -->
                            <!-- Packaging Description label -->

                            <v-col md="12" class="py-0 px-0 border-light-grey">
                              <v-row style="margin: 0% !important">
                                <v-col md="12" class="grey lighten-3">
                                  <label for="" class="field-label h4 mb-0 font-weight-bold">
                                    Packaging Description
                                  </label>
                                </v-col>

                                <v-col md="12" class="pb-0">
                                  <v-row>
                                    <!-- <v-col md="2" class="mb-0 py-0 text-left">
                                    <label for="uom-value" class="field-label">UOM Value</label>
                                  </v-col>
                                  <v-col md="3" class="mb-0 py-0 text-start">
                                    <label for="uom-measure" class="field-label">UOM Measure</label>
                                  </v-col>
                                  <v-col md="7" class="mb-0 py-0">
                                    <label for="unit_description_psu" class="field-label">
                                      UOM Description
                                    </label>
                                  </v-col> -->

                                    <!-- <v-col md="12" class="pt-1 pb-0 text-start">
                                      <label for="" class="field-label mt-0 mb-1"
                                        >Primary</label
                                      >
                                    </v-col> -->

                                    <v-col md="12" class="py-0">
                                      <div class="d-flex text-h5 rounded align-center py-1 px-3">
                                        <v-chip
                                          style="width: 100px"
                                          class="ma-2 text-white rounded-lg d-flex justify-center"
                                          color="blue"
                                        >
                                          Primary
                                        </v-chip>
                                        <div class="font-weight-bold ms-3">1</div>
                                        <div class="px-3">
                                          {{
                                            product.primary_sales_uom
                                              ? product.primary_sales_uom
                                              : "CAN"
                                          }}
                                        </div>
                                        <div class="px-3">=</div>
                                        <div class="px-3">
                                          {{
                                            product.unit_description_psu
                                              ? product.unit_description_psu
                                              : "100 ml"
                                          }}
                                        </div>
                                      </div>
                                      <div class="d-flex text-h5 rounded align-center py-1 px-3">
                                        <v-chip
                                          style="width: 100px"
                                          class="ma-2 text-white rounded-lg d-flex justify-center"
                                          color="red"
                                        >
                                          Secondary
                                        </v-chip>
                                        <div class="font-weight-bold ms-3">1</div>
                                        <div class="px-3">
                                          {{
                                            product.secondary_purchase_uom
                                              ? product.secondary_purchase_uom
                                              : "CTN"
                                          }}
                                        </div>
                                        <div class="px-3">=</div>

                                        <div class="px-3 d-flex align-center">
                                          <NumberInput
                                            hide-details
                                            type="number"
                                            :disabled="isDisabled"
                                            :loading="pageLoading"
                                            id="unit_description"
                                            :value="'13'"
                                            v-model="product.unit_value"
                                            :rules="[
                                              vrules.required(
                                                product.unit_value,
                                                'Department Category'
                                              ),
                                            ]"
                                            :class="{ required: !product.unit_value }"
                                            style="width: 100px"
                                            class="font-weight-bold"
                                            placeholder="Quantity"
                                          >
                                          </NumberInput>
                                          <div class="ms-2">
                                            {{
                                              product.primary_sales_uom
                                                ? product.primary_sales_uom
                                                : "CAN"
                                            }}

                                            <span class="px-2 font-weight-bold">X</span>
                                            {{
                                              product.unit_description_psu
                                                ? product.unit_description_psu
                                                : "100 ml"
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                      <v-row v-if="false">
                                        <v-col
                                          style="width: 50px"
                                          class="pa-0 pr-1 d-flex align-center"
                                        >
                                          <v-chip
                                            class="ma-2 mt-0 rounded-sm d-flex justify-center"
                                            color="blue"
                                            outlined
                                          >
                                            Primary
                                          </v-chip>
                                        </v-col>
                                        <v-col
                                          style="max-width: 50px"
                                          class="mt-0 pt-1 d-flex align-center"
                                        >
                                          <label for="" class="h5 field-label"> 1 </label>
                                          <!-- <TextInput hide-details readonly :value="1"> </TextInput> -->
                                        </v-col>

                                        <v-col
                                          style="max-width: 100px"
                                          class="mt-0 pt-1 d-flex align-center"
                                        >
                                          <label for="" class="h5 field-label">
                                            {{ product.primary_sales_uom }}
                                            CAN
                                          </label>
                                          <!-- <TextInput hide-details readonly :value="'CAN'"> -->
                                          <!-- </TextInput> -->
                                        </v-col>

                                        <v-col
                                          style="max-width: 50px"
                                          class="d-flex align-center justify-center"
                                        >
                                          <label for="" class="h5 field-label"> = </label>
                                        </v-col>

                                        <v-col md="" class="mt-0 pt-1 d-flex align-center">
                                          <label for="" class="h5 field-label">
                                            <!-- {{ product.unit_description_psu }} -->
                                            300ml
                                          </label>

                                          <!-- <TextInput hide-details readonly :value="'300ml'"> -->
                                          <!-- </TextInput> -->
                                        </v-col>
                                      </v-row>
                                    </v-col>

                                    <!-- <v-col md="12" class="pt-1 pb-0 text-start">
                                <label for="" class="field-label mt-0 mb-1"
                                  >Secondary</label
                                >
                              </v-col> -->

                                    <v-col md="12" v-if="false" class="py-0">
                                      <v-row>
                                        <v-col md="2" class="pa-0 pt-1 pr-1 d-flex align-center">
                                          <v-chip
                                            class="ma-2 mt-0 rounded-sm d-flex justify-center"
                                            color="red"
                                            outlined
                                            style="width: 100%"
                                          >
                                            Secondary
                                          </v-chip>
                                        </v-col>

                                        <v-col md="1" class="mt-0 py-0 d-flex align-center">
                                          <label for="" class="h5 field-label"> 1 </label>
                                          <!-- <TextInput hide-details readonly :value="1"> </TextInput> -->
                                        </v-col>

                                        <v-col md="2" class="mt-0 py-0 d-flex align-center">
                                          <label for="" class="h5 field-label">
                                            <!-- {{ product.secondary_purchase_uom }} -->
                                            CTN
                                          </label>
                                          <!-- <TextInput hide-details readonly :value="'CTN'">
                                        </TextInput> -->
                                        </v-col>

                                        <v-col md="1" class="d-flex align-center justify-center">
                                          <label for="" class="h5 field-label"> = </label>
                                        </v-col>

                                        <v-col md="2" class="mt-0 py-0 d-flex align-center">
                                          <!-- product.unit_description_spu -->
                                          <NumberInput
                                            hide-details
                                            type="number"
                                            :disabled="pageLoading"
                                            :loading="pageLoading"
                                            id="unit_description"
                                            :value="'12'"
                                            class="font-weight-bold"
                                            placeholder="Secondary UOM Description"
                                          >
                                          </NumberInput>
                                        </v-col>

                                        <v-col md="4" class="mt-0 py-0 d-flex align-center">
                                          <label for="" class="h5 field-label">
                                            300ml CAN <span class="h4 font-weight-bold">X</span> CTN
                                            <!-- {{
                                            `${
                                              (product.unit_description_psu
                                                ? product.unit_description_psu
                                                : "") +
                                              " " +
                                              (product.primary_sales_uom
                                                ? product.primary_sales_uom
                                                : "")
                                            } ${
                                              product.unit_description_psu &&
                                              product.primary_sales_uom
                                                ? "X"
                                                : ""
                                            } ${
                                              " " +
                                              (product.secondary_purchase_uom
                                                ? product.secondary_purchase_uom
                                                : "")
                                            }`
                                          }} -->
                                          </label>

                                          <!-- <TextInput
                                      hide-details
                                      :disabled="pageLoading"
                                      :loading="pageLoading"
                                      id="product-name-my"
                                      :value=""
                                      readonly
                                    ></TextInput> -->
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- UOM 1 -->
                    <!-- Unit Description (Primary sales UOM) -->

                    <!-- UOM 2 -->
                    <!-- <v-col md="12" class="mt-4 pb-0"> </v-col> -->

                    <!-- Warehouse -->
                    <v-col md="9" class="py-0">
                      <v-row
                        style="
                          background-color: #fff;
                          margin: 0% !important;
                          /* border: 1px solid var(--v-secondary-darken1); */
                        "
                      >
                        <v-col md="12" class="pt-0 px-0 border-light-grey">
                          <v-row style="margin: 0% !important">
                            <!-- Warehouse Quantity label -->
                            <v-col md="12" class="grey lighten-3">
                              <label for="" class="field-label h4 mt-1 mb-0 font-weight-bold"
                                >Warehouse Quantity</label
                              >
                            </v-col>

                            <v-col md="12" class="pr-3 pl-3 pb-0">
                              <v-row>
                                <!-- Sutra label -->
                                <v-col md="12" class="mt-0">
                                  <label for="" class="field-label h4 mt-0 mb-1 font-weight-bold"
                                    >Sutra store</label
                                  >
                                </v-col>

                                <v-col md="12" class="py-0">
                                  <div class="d-flex text-h5 rounded align-center py-1 px-3">
                                    <v-chip
                                      style="width: 100px"
                                      class="ma-2 text-white rounded-lg d-flex justify-center"
                                      color="blue"
                                    >
                                      Primary
                                    </v-chip>
                                    <div
                                      class="font-weight-bold ms-3"
                                      v-if="product.warehouse && product.warehouse.sutra"
                                    >
                                      <NumberInput
                                        v-if="$route.params.id"
                                        hide-details
                                        type="number"
                                        :disabled="isDisabled"
                                        :loading="pageLoading"
                                        id=""
                                        placeholder="Quantity"
                                        style="width: 100px"
                                        :value="'24'"
                                        v-model="product.warehouse.sutra.primary_quantity"
                                      >
                                      </NumberInput>
                                      <NumberInput
                                        v-else
                                        hide-details
                                        type="number"
                                        :disabled="isDisabled"
                                        :loading="pageLoading"
                                        id=""
                                        placeholder="Quantity"
                                        style="width: 100px"
                                        :value="'24'"
                                        v-model="product.warehouse.sutra.primary_quantity"
                                        :rules="[
                                          vrules.required(
                                            product.warehouse.sutra.primary_quantity,
                                            'Department Category'
                                          ),
                                        ]"
                                        :class="{
                                          required: !product.warehouse.sutra.primary_quantity,
                                        }"
                                      >
                                      </NumberInput>
                                      <!-- v-model="product.warehouse.sutra.primary_quantity" -->
                                    </div>
                                    <div class="px-3">
                                      <div class="px-3">
                                        {{
                                          product.primary_sales_uom
                                            ? product.primary_sales_uom
                                            : "CAN"
                                        }}
                                      </div>
                                      <!-- <TextInput
                                      readonly
                                      hide-details
                                      :disabled="pageLoading"
                                      :loading="pageLoading"
                                      id="product-name-en"
                                      placeholder="UOM"
                                      :value="'CAN'"
                                      style="width: 100px"
                                    >
                                    </TextInput> -->
                                      <!-- :value="product.primary_sales_uom" -->
                                    </div>
                                  </div>
                                </v-col>

                                <v-col md="12" class="py-0">
                                  <div class="d-flex text-h5 rounded align-center py-1 px-3">
                                    <v-chip
                                      style="width: 100px"
                                      class="ma-2 text-white rounded-lg d-flex justify-center"
                                      color="red"
                                    >
                                      Secondary
                                    </v-chip>
                                    <div
                                      class="font-weight-bold ms-3"
                                      v-if="product.warehouse && product.warehouse.sutra"
                                    >
                                      <NumberInput
                                        v-if="$route.params.id"
                                        hide-details
                                        type="number"
                                        :disabled="isDisabled"
                                        :loading="pageLoading"
                                        id=""
                                        v-model="product.warehouse.sutra.secondary_quantity"
                                        placeholder="Quantity"
                                        style="width: 100px"
                                        :value="'48'"
                                      >
                                      </NumberInput>
                                      <NumberInput
                                        v-else
                                        hide-details
                                        type="number"
                                        :disabled="isDisabled"
                                        :loading="pageLoading"
                                        id=""
                                        v-model="product.warehouse.sutra.secondary_quantity"
                                        :rules="[
                                          vrules.required(
                                            product.warehouse.sutra.secondary_quantity,
                                            'Department Category'
                                          ),
                                        ]"
                                        :class="{
                                          required: !product.warehouse.sutra.secondary_quantity,
                                        }"
                                        placeholder="Quantity"
                                        style="width: 100px"
                                        :value="'48'"
                                      >
                                      </NumberInput>
                                    </div>
                                    <div class="px-3">
                                      <div class="px-3">
                                        {{
                                          product.secondary_purchase_uom
                                            ? product.secondary_purchase_uom
                                            : "CTN"
                                        }}
                                      </div>
                                      <!-- <TextInput
                                      readonly
                                      hide-details
                                      :disabled="pageLoading"
                                      :loading="pageLoading"
                                      id="product-name-en"
                                      placeholder="UOM"
                                      :value="'CTN'"
                                      style="width: 100px"
                                    >
                                    </TextInput> -->
                                      <!-- :value="product.primary_sales_uom" -->
                                    </div>
                                  </div>
                                </v-col>

                                <v-col md="12" class="">
                                  <div class="px-5">
                                    <div class="d-flex align-center">
                                      <p class="text-h5 mb-0" style="font-weight: 500">
                                        Total Quantity :
                                      </p>
                                      <p class="mb-0 text-h5 font-weight-bold px-3">
                                        {{
                                          Number(product.unit_value) *
                                            product.warehouse?.sutra?.secondary_quantity +
                                          Number(product.warehouse?.sutra?.primary_quantity)
                                        }}
                                        {{
                                          product.primary_sales_uom
                                            ? product.primary_sales_uom
                                            : "CAN"
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </v-col>
                                <!-- <v-col md="2" class="pa-0 pt-0 pr-1 d-flex align-center">
                                <v-chip
                                  class="ma-2 mt-0 rounded-sm d-flex justify-center"
                                  color="blue"
                                  outlined
                                  style="width: 100%"
                                >
                                  Primary
                                </v-chip>
                              </v-col> -->

                                <!-- <v-col md="5" class="pt-0">
                                <TextInput
                                  readonly
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="product-name-en"
                                  placeholder="UOM"
                                  :value="product.primary_sales_uom"
                                >
                                </TextInput>
                              </v-col>

                              <v-col md="6" class="pt-0 pb-1">
                                <NumberInput
                                  hide-details
                                  type="number"
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id=""
                                  v-model="product.warehouse.sutra.secondary_quantity"
                                  placeholder="Quantity"
                                >
                                </NumberInput>
                              </v-col>

                              <v-col md="6" class="pt-0 pb-1">
                                <TextInput
                                  readonly
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="product-name-en"
                                  placeholder="UOM"
                                >
                                </TextInput>
                              </v-col> -->
                              </v-row>
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-stepper-content>

              <v-stepper-content
                step="3"
                class="py-0 px-1"
                style="height: calc(100vh - 188px); overflow-y: auto"
              >
                <!-- style="height: calc(100vh - 201px); overflow-y: auto" -->
                <v-row style="margin: 0% !important">
                  <!-- Supplier label -->
                  <v-col md="12" class="d-flex align-center justify-space-between">
                    <label for="" class="field-label h4 mt-0 mb-1 font-weight-bold">Supplier</label>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attr }">
                        <v-btn
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          class="white--text darken-4"
                          color="blue"
                          depressed
                          tile
                          v-on="on"
                          v-bind="attr"
                          v-on:click="addSupplier"
                        >
                          <!-- <v-icon class="">mdi-plus</v-icon> -->
                          Add Supplier
                        </v-btn>
                      </template>
                      <span>Add Supplier</span>
                    </v-tooltip>
                  </v-col>

                  <v-col md="12" class="py-0">
                    <!-- :style="`border: 1px solid var(--v-secondary-darken3); border-bottom: ${
                            index >= 0 || index === product.suppliers.length - 1
                              ? '1px solid var(--v-secondary-darken3);'
                              : ''
                          }; border-top: ${
                            index === 0 ? '1px solid var(--v-secondary-darken3)' : ''
                          } `" -->
                    <!-- supplierTableHeaders -->
                    <v-form
                      ref="thirdStep"
                      v-model="thirdStepValid"
                      lazy-validation
                      v-on:submit.stop.prevent="updateOrCreate"
                    >
                      <v-simple-table
                        fixed-header
                        style="height: calc(100vh - 280px)"
                        class="bt-table product-simple-table"
                      >
                        <template v-slot:default>
                          <!-- table titles -->
                          <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
                            <tr>
                              <th
                                v-for="(supplier_h, index) in supplierTableHeaders"
                                :key="
                                  '$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading
                                "
                                :style="{ maxWidth: `${supplier_h.width}px` }"
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div class="d-flex align-center" v-on="on" v-bind="attrs">
                                      <template v-if="supplier_h.heading === 'primary_sales_price'">
                                        <v-icon color="blue">mdi-circle</v-icon>
                                      </template>
                                      <template
                                        v-if="supplier_h.heading === 'secondary_purchase_price'"
                                      >
                                        <v-icon color="red">mdi-circle</v-icon>
                                      </template>
                                      <span class="text-truncate">
                                        {{ supplier_h.title }}
                                      </span>
                                    </div>
                                  </template>
                                  <span>{{ supplier_h.title }}</span>
                                </v-tooltip>
                              </th>
                            </tr>
                          </thead>

                          <tbody v-if="!pageLoading">
                            <template v-if="suppliers?.length">
                              <tr
                                :class="index % 1 === 0 && '#fff'"
                                v-for="(row, index) in suppliers"
                                class="product-listing-tr"
                                :key="'k_' + index"
                              >
                                <td
                                  style="border-right: none !important"
                                  :width="data_headings.width"
                                  v-for="(data_headings, idx) in supplierTableHeaders"
                                  :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                                  class="px-2"
                                >
                                  <!-- {{ data_headings.heading}} -->
                                  <SelectInput
                                    v-if="
                                      data_headings.heading === 'supplier_name' &&
                                      typeof row.id == 'number'
                                    "
                                    hide-details
                                    :disabled="ddisabled"
                                    :items="supplierList(suppliers[index].first_name)"
                                    item-text="company_name"
                                    item-value="first_name"
                                    :rules="[
                                      vrules.required(suppliers[index].first_name, 'supplier'),
                                    ]"
                                    :class="{ required: !suppliers[index].first_name }"
                                    :id="`$_product_supplier-${suppliers[index].first_name}_${
                                      idx + 1
                                    }`"
                                    placeholder="Select supplier"
                                    v-model="suppliers[index].first_name"
                                  ></SelectInput>
                                  <SelectInput
                                    v-if="
                                      data_headings.heading === 'supplier_name' &&
                                      typeof row.id != 'number'
                                    "
                                    hide-details
                                    :items="supplierList(suppliers[index].first_name)"
                                    item-text="company_name"
                                    item-value="first_name"
                                    :rules="[
                                      vrules.required(suppliers[index].first_name, 'supplier'),
                                    ]"
                                    :class="{ required: !suppliers[index].first_name }"
                                    :id="`$_product_supplier-${suppliers[index].first_name}_${
                                      idx + 1
                                    }`"
                                    placeholder="Select supplier"
                                    v-model="suppliers[index].first_name"
                                  ></SelectInput>
                                  <!-- <SelectInput 
                                  v-if="data_headings.heading === 'supplier_name' && row.id"
                                  hide-details
                                  :disabled="ddisabled"
                                  :items="supplierList(suppliers[index].first_name)"
                                  item-text="company_name"
                                  item-value="first_name"
                                  :rules="[vrules.required(suppliers[index].first_name, 'supplier')]"
                                        :class="{ required: !suppliers[index].first_name }"
                                  :id="`$_product_supplier-${suppliers[index].first_name}_${
                                    idx + 1
                                  }`"
                                  placeholder="Select supplier"
                                  v-model="suppliers[index].first_name"
                                ></SelectInput> -->
                                  <!-- {{ row[data_headings.heading] }} -->

                                  <TextInput
                                    v-if="data_headings.heading === 'supplier_part'"
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    id="uom-measure"
                                    v-model="row[data_headings.heading]"
                                    placeholder="Supplier part"
                                  ></TextInput>

                                  <template v-if="data_headings.heading === 'supplier_status'">
                                    <v-switch
                                      color="#2196F3"
                                      :value="false"
                                      :ripple="false"
                                      hide-details
                                      inset
                                      v-model="row[data_headings.heading]"
                                    ></v-switch>
                                  </template>

                                  <QuantityInput
                                    v-if="data_headings.heading === 'primary_sales_price'"
                                    hide-details
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    @keyup="searchPrimary(index)"
                                    type="number"
                                    id="primary-supplier-price"
                                    placeholder="Price"
                                    v-model="row[data_headings.heading]"
                                    :rules="[
                                      vrules.required(row[data_headings.heading], 'supplier'),
                                    ]"
                                    :class="{ required: !row[data_headings.heading] }"
                                    prefixText="RM"
                                  >
                                    <template v-slot:appendItem>
                                      <span>per {{ product.primary_sales_uom }}</span>
                                    </template>
                                  </QuantityInput>

                                  <QuantityInput
                                    v-if="data_headings.heading === 'secondary_purchase_price'"
                                    hide-details
                                    @keyup="searchSecondary(index)"
                                    id="secondary-supplier-price"
                                    placeholder="secondary"
                                    prefixText="RM"
                                    v-model="row[data_headings.heading]"
                                    :rules="[
                                      vrules.required(row[data_headings.heading], 'supplier'),
                                    ]"
                                    :class="{ required: !row[data_headings.heading] }"
                                    v-on:keydown:tab="
                                      index === suppliers?.length - 1 && addSupplier()
                                    "
                                  >
                                    <template v-slot:appendItem>
                                      <span> per {{ product.secondary_purchase_uom }}</span>
                                    </template>
                                  </QuantityInput>

                                  <div
                                    v-if="data_headings.heading === 'actions' && index > 0"
                                    class="d-flex align-center justify-center"
                                  >
                                    <v-tooltip bottom v-if="!row.supplier_name">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="red"
                                          large
                                          class=""
                                          v-on="on"
                                          v-bind="attrs"
                                          v-on:click="removeSupplier(index)"
                                          >mdi-delete</v-icon
                                        >
                                      </template>
                                      <span>Remove {{ row["supplier_name"] || "Supplier" }}</span>
                                    </v-tooltip>
                                    <!-- <v-tooltip bottom v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="red"

                                        large
                                        class=""
                                        v-on="on"
                                        v-bind="attrs"
                                        v-on:click="deleteSupplier(row.id)"
                                        >mdi-delete</v-icon
                                      >
                                    </template>
                                    <span>Remove {{ row["supplier_name"] || "Supplier" }}</span>
                                  </v-tooltip> -->
                                  </div>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                          <!-- <tfoot v-else>
                          <tr v-for="idr in 9" :key="idr">
                            <td v-for="idk in 9" :key="idk">
                              <v-skeleton-loader type="text"></v-skeleton-loader>
                            </td>
                          </tr>
                        </tfoot> -->
                        </template>
                      </v-simple-table>
                    </v-form>

                    <!-- <v-row style="margin: 0% !important">
                      <v-col md="3" class="py-0">
                        <label for="" class="field-label mt-0"> Supplier Name </label>
                      </v-col>
                      <v-col md="2" class="py-0">
                        <label for="" class="field-label mt-0"> Supplier Part </label>
                      </v-col>

                      <v-col md="1" style="max-width: fit-content" class="py-0">
                        <label for="" class="field-label mt-0"> Active </label>
                      </v-col>

                      <v-col md="2" style="min-width: fit-content" class="py-0">
                        <label for="" class="field-label mt-0"> Primary (Sales) price </label>
                      </v-col>

                      <v-col md="3" style="min-width: fit-content" class="py-0">
                        <label for="" class="field-label mt-0"> Secondary (Purchase) price </label>
                      </v-col>
                    </v-row> -->

                    <div v-if="false">
                      <v-row
                        :key="'$_' + product.suppliers[index].supplier_name + '_' + index + 1"
                        v-for="(supplier, index) in product.suppliers"
                        :style="{
                          marginLeft: `0% !important`,
                          marginRight: `0% !important`,
                          marginTop: `${index > 0 ? '12px' : '0px'} !important`,
                          marginBottom: `12px !important`,
                        }"
                      >
                        <v-col md="3" class="">
                          <SelectInput
                            hide-details
                            :items.sync="primarySalesUOMList"
                            :id="`$_product_supplier-${product.suppliers[index].supplier_name}_${
                              index + 1
                            }`"
                            v-model="product.suppliers[index].supplier_name"
                            placeholder="Select supplier"
                          ></SelectInput>
                        </v-col>
                        <v-col md="2" class="">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="uom-measure"
                            placeholder="Supplier part"
                            customClass="py-1"
                            v-model="product.suppliers[index].supplier_part"
                          ></TextInput>
                        </v-col>

                        <!-- Add button -->
                        <!-- Remove Button -->
                        <!-- <v-col md="2" class="pt-0">
                          <v-btn
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            class="white--text p-0"
                            color="green"
                            depressed
                            tile
                            v-on:click="addSupplier"
                          >
                            <v-icon class="">mdi-plus</v-icon>
                          </v-btn>
  
                          
                          <v-btn
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            class="white--text mr-2 p-0"
                            color="red"
                            depressed
                            tile
                            v-on:click="removeSupplier(index)"
                          >
                            <v-icon class="ml-1">mdi-minus</v-icon>
                          </v-btn>
                        </v-col> -->

                        <v-col
                          md="1"
                          style="max-width: 70px !important"
                          class="d-flex align-center justify-center"
                        >
                          <v-switch
                            color="#2196F3"
                            :value="false"
                            :ripple="false"
                            hide-details
                            inset
                            v-model="product.suppliers[index].supplier_status"
                          ></v-switch>
                        </v-col>

                        <v-col md="2" class="" style="min-width: 250px; max-width: max-content">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="primary-supplier-price"
                            placeholder="Price"
                            min="0"
                            max="9999"
                            v-on:keyup="
                              (e) => manageLimit(e, product.suppliers[index].primary_sales_price)
                            "
                            v-model.trim="product.suppliers[index].primary_sales_price"
                            prefixText="RM"
                          >
                            <!-- <template v-slot:prependItem>
                              <v-chip
                                style="width: 30px"
                                class="px-1 text-white rounded-0 d-flex justify-center"
                                color="blue"
                              >
                                RM
                              </v-chip>
                            </template> -->
                            <template v-slot:appendItem>
                              <span>CAN</span>
                              <!-- <span>{{ product.primary_sales_uom }}</span> -->
                            </template>
                          </TextInput>
                        </v-col>

                        <!-- <v-col md="2" style="max-width: 226px" class="">
                          <TextInput
                            readonly
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="product-name-en"
                            placeholder="UOM"
                            :value="product.primary_sales_uom"
                          >
                            <template v-slot:appendItem>
                              <v-chip
                                style="width: 85px"
                                class="ml-2 my-1 px-0 text-white rounded-lg d-flex justify-center"
                                color="blue"
                              >
                                Primary
                              </v-chip>
                            </template>
                          </TextInput>
                        </v-col> -->

                        <v-col md="2" style="min-width: 250px; max-width: max-content" class="">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="secondary-supplier-price"
                            placeholder="Price"
                            v-model="suppliers[index].secondary_purchase_price"
                            prefixText="RM"
                          >
                            <!-- <template v-slot:prependItem>
                              <v-chip
                                style="width: 30px"
                                class="px-0 text-white rounded-0 d-flex justify-center"
                                color="red"
                              >
                                RM
                              </v-chip>
                            </template> -->

                            <template v-slot:appendItem>
                              <span>CTN</span>
                              <!-- <span>{{ product.secondary_purchase_uom }}</span> -->
                            </template>
                          </TextInput>
                        </v-col>

                        <!-- <v-col md="2" style="max-width: 226px" class="">
                          <TextInput
                            readonly
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="product-name-en"
                            placeholder="UOM"
                            :value="product.secondary_purchase_uom"
                          >
                            <template v-slot:appendItem>
                              <v-chip
                                style="width: 85px"
                                class="ml-2 my-1 px-0 text-white rounded-lg d-flex justify-center"
                                color="red"
                              >
                                Secondary
                              </v-chip>
                            </template>
                          </TextInput>
                        </v-col> -->

                        <v-col
                          md="1"
                          style="max-width: min-content"
                          class="d-flex align-center justify-center px-0"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <!-- <v-btn
                                v-if="index > 0"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                class="white--text mr-2 p-0"
                                color="red"
                                depressed
                                tile
                                v-on="on"
                                v-bind="attrs"
                                v-on:click="removeSupplier(index)"
                              > -->
                              <v-icon
                                v-if="index > 0"
                                color="red"
                                large
                                class="px-3"
                                v-on="on"
                                v-bind="attrs"
                                v-on:click="removeSupplier(index)"
                                >mdi-delete</v-icon
                              >
                              <!-- </v-btn> -->
                            </template>
                            <span
                              >Remove
                              {{ product.suppliers[index].supplier_name || "Supplier" }}</span
                            >
                          </v-tooltip>
                        </v-col>

                        <!-- <template v-slot:appendItem>
                              <v-chip
                                style="width: 70px"
                                class="ml-2 my-1 px-0 text-white rounded-lg d-flex justify-center"
                                color="blue"
                              >
                                Primary
                              </v-chip>
                            </template> -->

                        <!-- <v-col md="5" class="pt-0">
                          <TextInput
                            readonly
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="product-name-en"
                            placeholder="UOM"
                            :value="product.primary_sales_uom"
                          >
                          </TextInput>
                        </v-col> -->

                        <!-- <v-col md="5" class="py-0">
                          <TextInput
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="product-name-en"
                            placeholder="Supplier price"
                            v-model="supplier.secondary_supplier_price"
                          >
                          </TextInput>
                        </v-col>
  
                        <v-col md="2" class="py-0">
                          <TextInput
                            readonly
                            hide-details
                            :disabled="pageLoading"
                            :loading="pageLoading"
                            id="product-name-en"
                            placeholder="UOM"
                            :value="product.secondary_purchase_uom"
                          >
                          </TextInput>
                        </v-col> -->
                        <!-- <v-col md="12" class="py-0">
                          <v-divider
                            v-if="
                              product.suppliers.length > 1 && index !== product.suppliers.length - 1
                            "
                          ></v-divider>
                        </v-col> -->
                      </v-row>

                      <v-col md="12" class="d-flex justify-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attr }">
                            <v-btn
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              class="white--text darken-4"
                              color="blue"
                              depressed
                              tile
                              v-on="on"
                              v-bind="attr"
                              v-on:click="addSupplier"
                            >
                              <!-- <v-icon class="">mdi-plus</v-icon> -->
                              Add Supplier
                            </v-btn>
                          </template>
                          <span>Add Supplier</span>
                        </v-tooltip>
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <!-- <v-col md="6" class="pt-0"> </v-col>
              <v-col md="6" class="pt-0">
                <v-row class="pt-0 pr-1 p-4">
                  <v-col
                    md="12"
                    class="pa-5"
                    style="background-color: #f5f5f5; border: 1px solid var(--v-secondary-darken1)"
                  >
                  </v-col>
                  <v-col md="12" class="mt-4 pb-0"> </v-col>
                </v-row>
              </v-col> -->
          <!-- </v-row>
          </v-container> -->
        </v-form>

        <Dialog :dialog="addAdjustment" fullscreen :dense="true">
          <template v-slot:title>
            <h1 class="custom-header-blue-text m-0">Add Adjustment</h1>
          </template>

          <template #subtitle>
            <p class="field-label mb-0">
              Please fill in the information below. The field labels marked with
              <span class="red--text font-weight-bold">*</span> are required input fields.
            </p>
          </template>

          <template v-slot:body>
            <AddAdjustment />
          </template>

          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
            >
              <!-- v-on:click="saveVoucher()" -->
              Add
            </v-btn>
            <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()">
              Cancel
            </v-btn>
          </template>
        </Dialog>

        <Dialog style="z-index: 999" :dialog="dialog.isOpen" :dialogWidth="dialog.dialogWidth">
          <template v-slot:title>
            <div class="d-flex align-center justify-space-between" style="width: 100%">
              Add{{ dialog.title }}
              <v-btn
                :disabled="pageLoading"
                :loading="pageLoading"
                color="white--text blue darken-4"
                depressed
                tile
                @click="addItemToTheList"
              >
                New {{ dialog.title }}
              </v-btn>
            </div>
          </template>
          <template v-slot:body>
            <v-container fluid class="pa-0">
              <v-row
                style="
                  margin-top: 8px !important;
                  margin-left: 0% !important;
                  margin-right: 0% !important;
                "
                v-for="(brand, bIndx) in prod_lists[leverageStrings(dialog.title)]"
                :key="`$_brand_${bIndx}_`"
                class="h1 my-1"
              >
                <v-col md="11">
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    :id="`brand_add_listing_${bIndx}`"
                    :placeholder="`Enter ${dialog.title}`"
                    :value="brand.text"
                    v-on:change="
                      (event) => {
                        prod_lists[leverageStrings(dialog.title)][bIndx].text = event;
                        prod_lists[leverageStrings(dialog.title)][bIndx].value =
                          leverageStrings(event);
                      }
                    "
                  >
                    <!-- v-model="brand.text" -->
                  </TextInput>
                </v-col>
                <v-col md="1" class="pa-0 d-flex align-center justify-center">
                  <v-icon
                    style="font-size: 30px"
                    v-on:click="removeFromList(bIndx)"
                    color="red"
                    outlined
                    >delete</v-icon
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
            >
              <!-- v-on:click="saveVoucher()" -->
              Add
            </v-btn>
            <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()">
              Cancel
            </v-btn>
          </template>
        </Dialog>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { toSafeInteger, cloneDeep } from "lodash";
import TextInput from "@/view/components/TextInput";
import ImageTemplate from "@/view/components/Image";
import Dialog from "@/view/components/Dialog";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_PRODUCT, UPDATE_PRODUCT, GET_PRODUCT } from "@/core/lib/product.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { head, isEmpty } from "lodash";
import FileManagerMixin from "@/core/mixins/file-manager/file.manager.mixin";
import { formatMoney } from "accounting-js";
import QuantityInput from "@/view/components/QuantityInput";

import { QUERY, DELETE } from "@/core/services/store/request.module";
import AddAdjustment from "./Add-Adjustment.vue";
// import ImageUpload from "@/public/media/svg/files/imageupload.svg";
import { filter, find } from "lodash";
export default {
  mixins: [FileManagerMixin],
  name: "Product-create",
  title: "Create Product",
  data() {
    return {
      addAdjustment: false,
      pageLoading: false,
      isDisabled: false,
      product_supplierss: [],
      brands: [],
      ddisabled: true,
      othercats: [],
      departments: [],
      filterDepartments: [],
      categorys: [],
      formValid: false,
      secondStepValid: true,
      firstStepValid: true,
      thirdStepValid: true,

      firstStep: 1,
      secondStep: 2,
      productId: null,
      listCountry: [
        {
          text: "Singapore",
          value: "singapore",
        },
        {
          text: "Malaysia",
          value: "malaysia",
        },
        {
          text: "Australia",
          value: "australia",
        },
      ],

      primarySalesUOMList: [
        // {
        //   text: "CAN",
        //   value: "CAN",
        // },
        // {
        //   text: "CTN",
        //   value: "CTN",
        // },
        // {
        //   text: "BTL",
        //   value: "BTL",
        // },
        // {
        //   text: "PKT",
        //   value: "PKT",
        // },
        // {
        //   text: "KG",
        //   value: "KG",
        // },
        // {
        //   text: "PCK",
        //   value: "PCK",
        // },
        // {
        //   text: "BAG",
        //   value: "BAG",
        // },
        // {
        //   text: "TRAY",
        //   value: "TRAY",
        // },
        // {
        //   text: "BOX",
        //   value: "BOX",
        // },
        // {
        //   text: "Whole",
        //   value: "Whole",
        // },
        // {
        //   text: "PCS",
        //   value: "PCS",
        // },
        // {
        //   text: "NONE",
        //   value: "NONE",
        // },
      ],

      // supplier_form
      supplier: {
        id: null,
        supplier: null,
        supplier_part: null,
        supplier_status: false,
        primary_sales_price: null,
        secondary_purchase_price: null,
      },
      suppliers: [
        {
          id: null,
          supplier_name: null,
          supplier_part: null,
          supplier_status: false,
          primary_sales_price: null,
          secondary_purchase_price: null,
        },
      ],
      product: {
        name_en: null,
        name_cn: null,
        name_my: null,
        brand: null,
        category: null,
        deptCategory: null,
        otherCategory: null,
        reorder_trigger: null,
        reorder_type: { text: "Primary", value: "primary" },
        primary_sales_uom: null,
        secondary_purchase_uom: null,
        unit_description_psu: null,
        unit_description_spu: null,
        unit_value: null,
        image: null,
        profile_logo: null,

        // All suppliers

        // Both warehouses
        warehouse: {
          sutra: {
            primary_quantity: null,
            secondary_quantity: null,
          },
          nusa: {
            primary_quantity: null,
            secondary_quantity: null,
          },
        },
      },

      // top stepper
      stepper: {
        value: 1,
        header: [{ title: "Basic Details" }, { title: "UOM" }, { title: "Supplier" }],
      },

      prod_lists: {
        // brand: [
        //   {
        //     text: "Videocon",
        //     value: "videocon",
        //   },
        //   {
        //     text: "Samsung",
        //     value: "samsung",
        //   },
        //   {
        //     text: "Cristofori",
        //     value: "cristofori",
        //   },
        //   {
        //     text: "Medeli",
        //     value: "medeli",
        //   },
        //   {
        //     text: "Greg Bennett",
        //     value: "greg_bennett",
        //   },
        //   {
        //     text: "FSF",
        //     value: "fsf",
        //   },
        // ],
        // category: [
        //   {
        //     text: "Disposable",
        //     value: "disposable",
        //   },
        //   {
        //     text: "Maintainance",
        //     value: "maintainance",
        //   },
        //   {
        //     text: "Adhoc",
        //     value: "adhoc",
        //   },
        //   {
        //     text: "Equipment",
        //     value: "equipment",
        //   },
        //   {
        //     text: "Bar",
        //     value: "bar",
        //   },
        //   {
        //     text: "Chinese",
        //     value: "chinese",
        //   },
        //   {
        //     text: "Vegetable",
        //     value: "vegetable",
        //   },
        //   {
        //     text: "Western",
        //     value: "western",
        //   },
        //   {
        //     text: "CW",
        //     value: "cw",
        //   },
        //   {
        //     text: "Marketing",
        //     value: "marketing",
        //   },
        //   {
        //     text: "Service",
        //     value: "service",
        //   },
        // ],
        // department_category: [
        //   {
        //     text: "Kitchen",
        //     value: "kitchen",
        //   },
        //   {
        //     text: "Bar Alcoholic",
        //     value: "bar_alcoholic",
        //   },
        //   {
        //     text: "Non Bar Alcoholic",
        //     value: "non_bar_alcoholic",
        //   },
        // ],
      },

      prod_lists_add_templates: {
        brand: {
          text: "",
          value: "",
          id: "",
        },
        category: {
          text: "",
          value: "",
          id: "",
        },
        department_category: {
          text: "",
          id: "",
          value: "",
        },
      },

      dialog: {
        isOpen: false,
        dialogWidth: 500,
        title: "",
      },

      supplierTableHeaders: [
        { title: "Supplier Name", heading: "supplier_name", width: 280 },
        { title: "Supplier Part", heading: "supplier_part", width: 150 },
        { title: "Active", heading: "supplier_status", value: true, width: 50 },
        { title: "Primary (Sales) Price", heading: "primary_sales_price", width: 140 },
        { title: "Secondary (Purchase) Price", heading: "secondary_purchase_price", width: 140 },
        { title: "", heading: "actions", width: 60 },
      ],
    };
  },
  components: {
    TextInput,
    SelectInput,
    NumberInput,
    ImageTemplate,
    QuantityInput,
    Dialog,
    AddAdjustment,
    // ImageUpload,
  },
  computed: {
    // mod_Suppliers() {
    //   return this.product.suppliers;

    // },
    getProfileImage() {
      return isEmpty(this.product.profile_logo) === false
        ? this.product.profile_logo.file.url
        : process.env.VUE_APP_BASE_URL + "media/misc/no_photo_found.png";
    },
    supplierList: () => {
      return function (id) {
        let _vendorList = cloneDeep(this.product_supplierss);
        console.log(id);
        console.log(_vendorList, "_vendorList");
        const newArray = filter(_vendorList, (row) => {
          console.log({ row, id });
          console.log({ sss: row.first_name != id });
          if (row.first_name != id) {
            console.log({ inner_row: this.product_supplierss });
            console.log({ first_name: row.first_name });
            if (!find(this.suppliers, (inner_row) => inner_row.first_name == row.first_name)) {
              return row;
            }
          } else {
            return row;
          }
        });
        console.log({ newArray });
        return newArray;
      };
    },
  },
  watch: {
    // addSupplier() {
    //   alert("hello")
    //   this.addSupplier();
    // },
  },
  methods: {
    selectsecondary(value) {
      console.log(value, "value");
      if (value == this.product.primary_sales_uom) {
        this.product.secondary_purchase_uom = null;
        return false;
      }
    },
    selectprimary(value) {
      console.log(value, "value");
      if (value == this.product.secondary_purchase_uom) {
        this.product.primary_sales_uom = null;
        return false;
      }
    },
    deleteSupplier(id) {
      this.$store
        .dispatch(DELETE, {
          url: `delete-product-suppliers/${id}`,
        })
        .then((data) => {
          this.product_supplierss = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.getProductData();
        });
    },
    //     supplierListt(e){
    //      console.log(e)
    //      let id = e;
    //      console.log(id)
    //      return function (id)
    //  {
    //         //console.log(id,'id');
    //         let _vendorList = cloneDeep(this.product_supplierss);
    //         console.log(_vendorList,'_vendorList')
    //         //  console.log(this.productCreate.suppliers,'111')
    //         const newArray = filter(_vendorList, (row) => {
    //           if (row.first_name != id) {
    //             if (
    //               !find(
    //                 this.supplier_name,
    //                 (inner_row) => inner_row == row.first_name
    //               )
    //             ) {
    //               return row;
    //             }
    //           } else {
    //             return row;
    //           }
    //         });
    //         return newArray;
    //       };

    //     },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    selectUoms() {
      const _this = this;
      this.$store
        .dispatch(QUERY, {
          url: `uoms`,
        })
        .then((data) => {
          _this.primarySalesUOMList = data;

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
       console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    async getProductData() {
      this.product_id = this.$route.params.id;

      if (this.product_id) {
        try {
          const data = await GET_PRODUCT(this.product_id);
          // _this.getOrders();

          this.product = data.rows;
          console.log(this.product, "product");
          // this.unit_value = data.rows.unit_value;

          this.suppliers = data.rows.supplier;

          console.log(data.rows, "product");

          this.product.deptCategory = Number(data.rows.deptCategory);
          this.filterDepartments = [
            {
              id: data.rows.category,
              text: data.rows.category_name,
              value: data.rows.category_name,
              name: data.rows.category_name,
            },
          ];

          this.product.category = data.rows.category;
          this.product.warehouse.sutra = data.sutra;
          this.product.warehouse.sutra.primary_quantity = data.sutra.primary_qty;
          this.product.warehouse.sutra.secondary_quantity = data.sutra.secondary_qty;
          this.product.warehouse.nusa = data.nusa;
          this.product.warehouse.nusa.primary_quantity = data.nusa.primary_qty;
          this.product.warehouse.nusa.secondary_quantity = data.nusa.secondary_qty;
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      let fileExtention = param?.type.split("/");
      fileExtention = fileExtention[0];
      if (fileExtention != "image") {
        _this.$store.commit(SET_ERROR, [{ model: true, message: "Only Image required" }]);
        return false;
      }

      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.product.profile_logo = head(response);
          _this.product.image = this.product.profile_logo.id;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    filter_category(param) {
      console.log(this.departments, "departments");

      this.filterDepartments = filter(this.departments, {
        parent_id: param,
      });
      this.product.category = null;
      console.log(this.filterDepartments, "this.filterDepartments");
    },

    getcategory(param) {
      this.$store
        .dispatch(QUERY, {
          url: `check-category/${param}`,
        })
        .then((data) => {
          this.departments = data;
          console.log(data);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },

    searchPrimary(index) {
      if (this.suppliers[index].supplier_status == true) {
        this.suppliers[index].secondary_purchase_price =
          this.product.unit_value * this.suppliers[index]?.primary_sales_price;
        this.suppliers[index].primary_sales_price = this.suppliers[index]?.primary_sales_price;
      }
    },
    searchSecondary(index) {
      if (this.suppliers[index].supplier_status == true) {
        this.suppliers[index].primary_sales_price =
          this.suppliers[index]?.secondary_purchase_price / this.product.unit_value;
        this.suppliers[index].secondary_purchase_price =
          this.suppliers[index]?.secondary_purchase_price;
      }
    },
    getSupplier() {
      this.$store
        .dispatch(QUERY, {
          url: "suppliers",
        })
        .then((data) => {
          this.product_supplierss = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "data",
        })
        .then((data) => {
          this.brands = data.brands;
          this.prod_lists.brand = this.brands;
          this.categorys = data.departments;
          this.departments = data.categorys;
          this.othercats = data.othercats;
          console.log(this.othercats);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    tabPress() {
      console.log("tabPress");
    },
    addItemToTheList() {
      const itemToAdd = {
        ...this.prod_lists_add_templates[this.leverageStrings(this.dialog.title)],
      };
      this.prod_lists[this.leverageStrings(this.dialog.title)].unshift(itemToAdd);
    },
    removeFromList(index) {
      this.prod_lists[this.leverageStrings(this.dialog.title)].splice(index, 1);
    },
    leverageStrings(string) {
      return string.toLowerCase().split(" ").join("_");
    },
    closeDialog() {
      this.dialog = {
        ...this.dialog,
        isOpen: false,
        title: "",
      };
      this.addAdjustment = false;
    },
    openDialog(title, dialogWidth, isOpen) {
      this.dialog = {
        ...this.dialog,
        title,
        dialogWidth,
        isOpen,
      };
    },
    // primaryImage() {
    //   const primary = find(this.product.image, { primary: 1 });
    //   if (!primary) {
    //     if (!this.product.image) {
    //       return null;
    //     }
    //     this.product.image[0].primary = 1;
    //     return this.product.image[0].url;
    //   }
    //   return primary.url;
    // },
    fileInput() {
      const { fileInput } = this.$refs;
      if (fileInput && fileInput.$refs) {
        const { input } = fileInput.$refs;
        if (input) {
          input.click();
        }
      }
    },
    async handleFilesUpload() {
      try {
        const { fileInput } = this.$refs;
        if (fileInput && fileInput.$refs) {
          const { input } = fileInput.$refs;
          if (input && input.files) {
            const formData = new FormData();
            // for (let i = 0; i < input.files.length; i++) {
            formData.append(`files[0]`, input.files[0]);
            // }
            this.pageLoading = true;
            // const files = await UPDATE_FILES(formData);
            // this.product.image = files;
          }
        }
      } catch (error) {
        // this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        console.log({ error });
      } finally {
        this.pageLoading = false;
      }
    },
    removeImage() {},
    pageTitle() {
      if (this.productId) {
        return "Update Product";
      }
      return "Create Product";
    },
    //   async updateOrCreate() {
    //     const _this = this;
    //     // console.log(_this.product);

    //     if (!_this.$refs.productAddForm.validate()) {
    //       return false;
    //     }

    //     try {
    //       _this.pageLoading = true;

    //       if (_this.customerId) {
    //         console.log(this.product);
    //         const customer = await UPDATE_PRODUCT(_this.customerId, _this.customer);
    //         _this.$router.replace({
    //           name: "customer-detail",
    //           params: { id: customer.id },
    //           query: { t: new Date().getTime() },
    //         });
    //         _this.$store.commit(SET_MESSAGE, [
    //           { model: true, message: "Customer Updated Successfully." },
    //         ]);
    //       } else {
    //         const customer = await CREATE_PRODUCT({..._this.product, suppliers: _this.supplier});
    //         _this.$router.replace({
    //           name: "customer-detail",
    //           params: { id: customer.id },
    //           query: { t: new Date().getTime() },
    //         });
    //         _this.$store.commit(SET_MESSAGE, [
    //           { model: true, message: "Customer Created Successfully." },
    //         ]);
    //       }
    //     } catch (error) {
    //       _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //     } finally {
    //       _this.pageLoading = false;
    //     }
    //   },
    async updateOrCreate() {
      const _this = this;

      // if (stepper.value === 1) {
      //               if (firstStepValid) {
      //                 stepper.value++;
      //               }
      //               // updateOrCreate();
      //             } else if(stepper.value ===2) {
      //               if (secondStepValid) {
      //                 stepper.value++;
      //               }
      //             }
      //             else if(stepper.value ===3) {
      //               updateOrCreate();
      //             }

      if (_this.stepper.value == 1 && _this.$refs.firstStep.validate()) {
        _this.stepper.value++;
      } else if (_this.stepper.value == 2 && _this.$refs.secondStep.validate()) {
        _this.stepper.value++;
      } else if (_this.stepper.value == 3 && _this.$refs.thirdStep.validate()) {
        try {
          _this.pageLoading = true;

          if (_this.$route.params.id) {
            _this.product.image = this.product?.profile_logo?.id;
            console.log(_this.product.image);
            const product = await UPDATE_PRODUCT(_this.$route.params.id, _this.product);
            _this.$router.replace({
              name: "product",
              params: { id: product.id },
              query: { t: new Date().getTime() },
            });
            _this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Product Updated Successfully." },
            ]);
          } else {
            _this.product.supplier = _this.suppliers;
            const customer = await CREATE_PRODUCT(_this.product);
            console.log(customer);
            _this.$router.replace({
              name: "product",
              params: { id: customer.id },
              query: { t: new Date().getTime() },
            });
            _this.$store.commit(SET_MESSAGE, [
              { model: true, message: "Product Created Successfully." },
            ]);
          }
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
        }
      }

      // if (_this.stepper.value == 3 && !_this.$refs.secondStepValid.validate()) {
      //   return false;
      // }
    },
    // async getCustomer() {
    //   const customer = await GET_PRODUCT(this.customerId);
    //   this.customer = {
    //     title: customer.title,
    //     first_name: customer.first_name,
    //     last_name: customer.last_name,
    //     email: customer.email,
    //     date_of_birth: customer.date_of_birth,
    //     gender: customer.gender,
    //     phone_number: customer.phone_number,
    //     address_line_1: customer.address_line_1,
    //     address_line_2: customer.address_line_2,
    //     unit_number: customer.unit_number,
    //     postal_code: customer.postal_code,
    //     country: customer.country,
    //   };
    //   this.$store.dispatch(SET_BREADCRUMB, [
    //     { text: "Customer", disabled: true },
    //     { text: "Update", disabled: true },
    //     { text: customer.barcode, disabled: true },
    //   ]);
    // },
    addSupplier() {
      const supplier = { ...this.supplier, id: "k" + this.suppliers.length + 1 };
      this.suppliers.push(supplier);
    },

    removeSupplier(index) {
      this.suppliers.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);
    this.selectUoms();

    const { name, params } = this.$route;
    if (name === "product-update") {
      const { id } = params;

      if (id) {
        this.productId = toSafeInteger(id);
        (this.isDisabled = true),
          // this.getCustomer();
          this.getProductData();
      } else {
        this.goBack();
      }
    }
    this.getSupplier();
    this.getData();
  },
};
</script>

<style scoped>
.v-input {
  margin-top: 0% !important;
}

.field-label {
  font-size: 1rem;
  font-weight: 500;
}

.left-idf-primary {
  border-left: 10px solid #5cb85c;
}

.idf_mark_primary {
  background-color: #5cb85c;
}

.idf_mark_secondary {
  background-color: #e64a4a;
}

.left-idf-secondary {
  border-left: 10px solid #e64a4a;
}

.product-image-hover {
  transition: ease-in-out 0.3s;
}

.product-image-hover:hover {
  background: rgba(0, 0, 0, 0.041) !important;
}

.product-simple-table tr:hover {
  background: #fff !important;
}
</style>
